import React, { useEffect } from 'react'
import BasePage from '../../../../components/basePage'
import { navigate } from 'gatsby'
import {
	Button,
	Heading,
	Flex,
	Text,
	useToast,
	Textarea
} from '@chakra-ui/react'
import { useState } from 'react'
import Modal from '../../../../components/modal'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getGroup, updateGroup } from '../../../../services/group'
import Spinner from '../../../../components/spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { errorToastOptions } from '../../../../helpers'
import eventTracking from '../../../../services/eventTracking'
import AdminLogin from '../../../login/adminLogin'
import { getIsAdmin } from '../../../../helpers/database'

const Team = ({ id }) => {
	const toast = useToast()
	const queryClient = useQueryClient()
	const [modal, setModal] = useState(false)
	const [sendEvent, setSendEvent] = useState(false)
	const [editing, setEditing] = useState(false)
	const { data, status } = useQuery(['group', id], getGroup)
	const { name } = data || {}

	const [userIsAdmin, setUserIsAdmin] = useState(getIsAdmin())

	const resolver = yupResolver(
		object()
			.shape({
				name: string().notBadWords().trim().required().min(2)
			})
			.required()
	)
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	const nameMutation = useMutation(updateGroup, {
		onSuccess: async (data) => {
			// console.log('updateGroup', data)
			await queryClient.invalidateQueries(['group', id], {
				exact: true
			})
			setEditing(false)
		},
		onError: (error) => {
			// console.log(error)
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const handleEditName = () => {
		const data = getValues()
		if (!data.name) return
		nameMutation.mutate({ id: id, name: data.name })
	}

	const description = (
		<>
			Play on your own, as part of a team, or both.
			<br />
			By default you’ll be competing against the rest of your group.{' '}
			<br />
			The leader booker can also create teams within your group, don’t
			worry if they’re uneven numbers – we’ve sorted out the maths for you
			so it’s always a level playing field.
		</>
	)

	useEffect(() => {
		if (!!sendEvent) {
			eventTracking('click_how_teams_work')
		}
	}, [sendEvent])

	useEffect(() => {
		if (status === 'error' || (status === 'success' && !name)) {
			navigate('/groups/')
		}

		if (status === 'success' && !!name) {
			setValue('name', data.name)
		}
	}, [data, status])

	if (status === 'loading' || status === 'error') {
		return (
			<BasePage theme="dark" showMenuBtn={true}>
				{status === 'loading' && <Spinner />}
			</BasePage>
		)
	}

	const modalView = (
		<Modal
			opened={modal}
			// onClose={() => setModal(false)}
			title="How teams work"
			description={description}
			primaryBtn={
				<Button variant="primary" onClick={() => setModal(false)}>
					Got It!
				</Button>
			}
		>
			<Text
				variant="m"
				fontWeight="700"
				mt="15px"
				px="20px"
				textAlign="center"
			>
				Tip: You can change teams but only before you start playing, so
				choose your friends carefully!
			</Text>
		</Modal>
	)

	if (!userIsAdmin) return <AdminLogin setUserIsAdmin={setUserIsAdmin} />

	return (
		<BasePage theme="dark" showMenuBtn={true}>
			{editing ? (
				<form
					onSubmit={handleSubmit(handleEditName)}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<Textarea
						type="text"
						name="name"
						mt="6px"
						mb="10px"
						defaultValue={name || ''}
						variant="filled"
						{...register('name')}
					/>
					<Text variant="error" as="span" mt="0px">
						{errors?.name?.message}
					</Text>
				</form>
			) : (
				<Heading mt="20px" as="h1" color="white">
					{name || ''}
				</Heading>
			)}
			<Flex justifyContent="center" alignItems="center">
				{editing && (
					<Button
						type="submit"
						variant="link"
						onClick={handleSubmit(handleEditName)}
						disabled={nameMutation.isLoading}
						isLoading={nameMutation.isLoading}
					>
						Save
					</Button>
				)}
				{!editing && (
					<Button variant="link" onClick={() => setEditing(true)}>
						Edit name
					</Button>
				)}
			</Flex>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				mt="40px"
			>
				<Heading as="h4" color="white" textAlign="center" mb="10px">
					Teams
				</Heading>
			</Flex>
			{modalView}
		</BasePage>
	)
}

export default Team
